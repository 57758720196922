export function MaskParsed({ maskType, maskString }) {
    //console.log(maskType, ":", maskString);

    let key = "1";

    let result = [];

    switch (maskType) {
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        /////////////////////Outputs////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////

        case "outputMask":
            //console.log(maskString);
            // 0 Power Cycle Active
            // 1 Aux Power Active
            // 2 Compressor Module Power Cycle Active
            if (Number(maskString[2])) {
                result.push(<div key={key}>Comp Power Cycling</div>);
                key++;
            }
            // 3 CCH Active
            if (Number(maskString[3])) {
                result.push(<div key={key}>CCH Active</div>);
                key++;
            }
            // 4 Subcooler Solenoid Active
            if (Number(maskString[4])) {
                result.push(<div key={key}>Subcooler Sol Active</div>);
                key++;
            }
            // 5 Supply Solenoid Active
            if (Number(maskString[5])) {
                result.push(<div key={key}>Supply 2 Sol Active</div>);
                key++;
            }
            // 6 Return Solenoid Active
            if (Number(maskString[6])) {
                result.push(<div key={key}>Return 2 Sol Active</div>);
                key++;
            }
            // 7 Fresh Air Fan Active
            if (Number(maskString[7])) {
                result.push(<div key={key}>Fresh Air Active</div>);
                key++;
            }
            // 8 LED Power Active
            if (Number(maskString[8])) {
                result.push(<div key={key}>LEDs Active</div>);
                key++;
            }
            // 9 Heaters Active
            if (Number(maskString[9])) {
                result.push(<div key={key}>Heaters Active</div>);
                key++;
            }
            // 10 Liquid Line Solenoid Active
            if (Number(maskString[10])) {
                result.push(<div key={key}>LQL Sol Active</div>);
                key++;
            }
            // 11 Hot Gas Bypass Solenoid Active
            if (Number(maskString[11])) {
                result.push(<div key={key}>HGB Sol Active</div>);
                key++;
            }
            // 12 Floor Heater
            if (Number(maskString[12])) {
                result.push(<div key={key}>Floor Heater Cycling</div>);
                key++;
            }
            // 13 Safety Alarm Relay Active
            if (Number(maskString[13])) {
                result.push(<div key={key}>Safety Siren Active</div>);
                key++;
            }
            // 14 EXV Run Command Active
            if (Number(maskString[14])) {
                result.push(<div key={key}>EXV Run Command Active</div>);
                key++;
            }
            // 15 HMI Power Cycle Active
            if (Number(maskString[15])) {
                result.push(<div key={key}>HMI Power Cycle Active</div>);
                key++;
            }
            // 16 Compressor Stage 1 Run Active
            if (Number(maskString[16])) {
                result.push(<div key={key}>Comp1 Run Active</div>);
                key++;
            }
            // 17 Compressor Stage 2 Run Active
            if (Number(maskString[17])) {
                result.push(<div key={key}>Comp2 Run Active</div>);
                key++;
            }
            // 18 Evap Run Active
            if (Number(maskString[18])) {
                result.push(<div key={key}>Evap Run Active</div>);
                key++;
            }
            // 19 Output_Command_Spare
            // 20 Condenser Run Active
            if (Number(maskString[20])) {
                result.push(<div key={key}>Cond Run Active</div>);
                key++;
            }
            // 21 System Scheduled Start Bit
            if (Number(maskString[21])) {
                result.push(<div key={key}>Schedule Active</div>);
                key++;
            }
            // 22 Position Exv1 Automode
            // 23 Position Exv2 Automode
            // 24 Speed Comp 1 Automode
            // 25 Speed Comp 2 Automode
            // 26 Speed Evap Automode
            // 27 Speed Condenser Automode
            // 28 Session Count
            // 29 Control Solenoids Automode
            // 30 Datalogger_Reboot
            // 31 Datalogger_Powercycle
            // 32 Disable_RoutineAutoRestart
            // 33 Manual_ServiceLockout
            // 34 HMI_Powercycle
            // 35 Water_Detected
            // 36 C1 Spare Relay #3
            // 37 C1 Machine Room Cooling Solenoid Active
            // 38 C1 Spare Relay #8
            // 39 C1 Spare Relay #11
            // 40 C1 Machine Room Fan
            // 41-43 Session Cryo Level (added PLC v10.2)

            // ...
            // 61 Left Evap Disabled
            if (Number(maskString[61])) {
                result.push(<div key={key}>Left Evap Bypassed</div>);
                key++;
            }
            // 62 Right Evap Disabled
            if (Number(maskString[62])) {
                result.push(<div key={key}>Right Evap Bypassed</div>);
                key++;
            }
            return result;

        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////Warnings////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////

        case "warningMask":
            // 0 Fresh Air Fan Failed
            if (Number(maskString[0])) {
                result.push(<div key={key}>Fresh Air Fan Failed</div>);
                key++;
            }
            // 1 CB2 Tripped
            if (Number(maskString[1])) {
                result.push(<div key={key}>CB2 Tripped</div>);
                key++;
            }
            // 2 Evap VFD 1050 Fault Reset
            if (Number(maskString[2])) {
                result.push(<div key={key}>Evap VFD 1050 Fault Reset</div>);
                key++;
            }
            // 3 Cond VFD 1050 Fault Reset
            if (Number(maskString[3])) {
                result.push(<div key={key}>Cond VFD 1050 Fault Reset</div>);
                key++;
            }
            // 4 CB5 Tripped
            if (Number(maskString[4])) {
                result.push(<div key={key}>CB5 Tripped</div>);
                key++;
            }
            // 5 CB6 Tripped
            if (Number(maskString[5])) {
                result.push(<div key={key}>CB6 Tripped</div>);
                key++;
            }
            // 6 CB7 Tripped
            if (Number(maskString[6])) {
                result.push(<div key={key}>CB7 Tripped</div>);
                key++;
            }
            // 7 CB8 Tripped
            if (Number(maskString[7])) {
                result.push(<div key={key}>CB8 Tripped</div>);
                key++;
            }
            // 8 Not Cooling After Schedule Started
            if (Number(maskString[8])) {
                result.push(<div key={key}>Not Cooling After Schedule Started</div>);
                key++;
            }
            // 9 Possible Resart After Cooling Interrupted
            if (Number(maskString[9])) {
                result.push(<div key={key}>Cooling Interrupted</div>);
                key++;
            }
            // 10 Sensor Failure Detected
            if (Number(maskString[10])) {
                result.push(<div key={key}>Sensor Failure Detected</div>);
                key++;
            }
            // 11 Fresh Air Fan Stuck
            if (Number(maskString[11])) {
                result.push(<div key={key}>Fresh Air Fan Stuck</div>);
                key++;
            }
            // 12 EVX1 Exceeded 55%
            if (Number(maskString[12])) {
                result.push(<div key={key}>EVX1 Exceeded 55%</div>);
                key++;
            }
            // 13 Ridge system has a motor bypassed
            if (Number(maskString[13])) {
                result.push(<div key={key}>Ridge system has a motor bypassed</div>);
                key++;
            }
            // 14 Water Detected in Evap Pan
            if (Number(maskString[14])) {
                result.push(<div key={key}>Water Detected in Evap Pan</div>);
                key++;
            }
            // 15 Warning: High head pressure >300psi on stage 1 or 2, for 2 hours accumulatively.
            if (Number(maskString[15])) {
                result.push(<div key={key}>High head pressure {">"}300psi</div>);
                key++;
            }
            // 16 Warning: High Compressor speeds >55Hz on either stage 1 or 2, for 2 hours accumulatively.
            if (Number(maskString[16])) {
                result.push(<div key={key}>High Compressor speeds {">"}55Hz</div>);
                key++;
            }
            // 17 Warning: High discharge temps >200°F stage 1 or 2, for 2 hours accumulatively.
            if (Number(maskString[17])) {
                result.push(<div key={key}>High discharge temps {">"}200°F</div>);
                key++;
            }
            // 18 Warning: High suction pressure >20psi on stage 1, for 2 hours accumulatively.
            if (Number(maskString[18])) {
                result.push(<div key={key}>High suction pressure {">"}20psi</div>);
                key++;
            }
            // 19 Warning: Low Subcooled temperature <20°F for 2 hours accumulatively.
            if (Number(maskString[19])) {
                result.push(<div key={key}>Low Subcooled temperature {"<"}20°F</div>);
                key++;
            }
            // 20 Warning: HMI got stuck in time
            if (Number(maskString[20])) {
                result.push(<div key={key}>HMI got stuck in time</div>);
                key++;
            }
            // ...
            // 27 Controller manually switched from Remote
            if (Number(maskString[27])) {
                result.push(<div key={key}>Controller manually switched from Remote</div>);
                key++;
            }
            // 28 Controller in Run Mode Change for over 12 Hours
            if (Number(maskString[28])) {
                result.push(<div key={key}>Controller in Run Mode Change for over 12 Hours</div>);
                key++;
            }
            // 29 User manually stopped Cooling.
            if (Number(maskString[29])) {
                result.push(<div key={key}>User manually stopped Cooling</div>);
                key++;
            }
            // 30 System regained power 10 minutes ago, after loss.
            if (Number(maskString[30])) {
                result.push(<div key={key}>System regained power 10 minutes ago</div>);
                key++;
            }
            // 31 Safety Defrost active > 5 minutes
            if (Number(maskString[31])) {
                result.push(<div key={key}>Safety Defrost active {">"} 5 minutes</div>);
                key++;
            }
            return result;

        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        //////////////////////Globals///////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////

        case "globalMask":
            // 0-1 Global_Condenser_Type
            switch (parseInt(maskString.slice(0, 2), 2)) {
                case 0:
                    result.push(<div key={key}>Condensing Style: Air Out</div>);
                    key++;
                    break;
                case 1:
                    result.push(<div key={key}>Condensing Style: Air In</div>);
                    key++;
                    break;
                case 2:
                    result.push(<div key={key}>Condensing Style: H2O</div>);
                    key++;
                    break;
                default:
                    break;
            }
            // 2 Global_System_Trans_Suction_0to500_S1
            if (Number(maskString[2])) {
                result.push(<div key={key}>S1 Suction Transducer: 500</div>);
                key++;
            }
            // 3 Global_System_Trans_Suction_0to500_S2
            if (Number(maskString[3])) {
                result.push(<div key={key}>S2 Suction Transducer: 500</div>);
                key++;
            }
            // 4 Global_VFDUseTCP
            if (Number(maskString[4])) {
                result.push(<div key={key}>TCP Comms</div>);
                key++;
            } else {
                result.push(<div key={key}>SERIAL Comms</div>);
                key++;
            }
            // 5-7 Global_Temperature_Module
            switch (parseInt(maskString.slice(5, 8), 2)) {
                case 0:
                    result.push(<div key={key}>Temp Module: IF4U</div>);
                    key++;
                    break;
                case 1:
                    result.push(<div key={key}>Temp Module: IRT4</div>);
                    key++;
                    break;
                case 2:
                    result.push(<div key={key}>Temp Module: DAT 3019</div>);
                    key++;
                    break;
                case 3:
                    result.push(<div key={key}>Temp Module: DAT 8019</div>);
                    key++;
                    break;
                default:
                    break;
            }
            // 8 Global_AorB
            if (Number(maskString[8])) {
                result.push(<div key={key}>Code Version B/D</div>);
                key++;
            } else {
                result.push(<div key={key}>Code Version A/C</div>);
                key++;
            }
            // 9 Global_EXV1_Control
            if (Number(maskString[9])) {
                result.push(<div key={key}>Stage1 Valve: EXV1</div>);
                key++;
            } else {
                result.push(<div key={key}>Stage1 Valve: TXV</div>);
                key++;
            }
            // 10 Global_UseADFDriver
            if (Number(maskString[10])) {
                result.push(<div key={key}>ADF Driver</div>);
                key++;
            } else {
                result.push(<div key={key}>Enigma Module</div>);
                key++;
            }
            // 11 Global_Evap_Defrost
            if (Number(maskString[11])) {
                result.push(<div key={key}>Evap Defrost Enabled</div>);
                key++;
            }
            // 12 Global_System_Current_Module
            if (Number(maskString[12])) {
                result.push(<div key={key}>Current Module Enabled</div>);
                key++;
            }
            // 13-14 Global_System_Expansion_Tank
            switch (parseInt(maskString.slice(13, 15), 2)) {
                case 0:
                    result.push(<div key={key}>Large Expansion Tank</div>);
                    key++;
                    break;
                case 1:
                    result.push(<div key={key}>Small Expansion Tank</div>);
                    key++;
                    break;
                case 2:
                    result.push(<div key={key}>No Expansion Tank</div>);
                    key++;
                    break;
                default:
                    break;
            }
            // 15-17 Global_System_Voltage
            switch (parseInt(maskString.slice(15, 18), 2)) {
                case 0:
                    result.push(<div key={key}>230V 3Ø</div>);
                    key++;
                    break;
                case 1:
                    result.push(<div key={key}>230V 1Ø</div>);
                    key++;
                    break;
                case 2:
                    result.push(<div key={key}>460V</div>);
                    key++;
                    break;
                case 3:
                    result.push(<div key={key}>380V</div>);
                    key++;
                    break;
                case 4:
                    result.push(<div key={key}>400V</div>);
                    key++;
                    break;
                case 5:
                    result.push(<div key={key}>600V</div>);
                    key++;
                    break;
                default:
                    break;
            }
            // 18-21 Global_Chamber_Type
            switch (parseInt(maskString.slice(18, 22), 2)) {
                case 0:
                    result.push(<div key={key}>Model: PK</div>);
                    key++;
                    break;
                case 1:
                    result.push(<div key={key}>Model: RG</div>);
                    key++;
                    break;
                case 2:
                    result.push(<div key={key}>Model: E1</div>);
                    key++;
                    break;
                case 3:
                    result.push(<div key={key}>Model: C1</div>);
                    key++;
                    break;
                case 4:
                    result.push(<div key={key}>Model: C4</div>);
                    key++;
                    break;
                case 5:
                    result.push(<div key={key}>Model: BC</div>);
                    key++;
                    break;
                case 6:
                    result.push(<div key={key}>Model: CV</div>);
                    key++;
                    break;
                default:
                    break;
            }
            // 22-25 Global_Condenser_Model
            switch (parseInt(maskString.slice(22, 26), 2)) {
                case 0:
                    result.push(<div key={key}>Condenser Model: Heatcraft {"(No VFD)"}</div>);
                    key++;
                    break;
                case 1:
                    result.push(<div key={key}>Condenser Model: HX {"(H2O)"}</div>);
                    key++;
                    break;
                case 2:
                    result.push(<div key={key}>Condenser Model: Heatcraft {"(VFD)"}</div>);
                    key++;
                    break;
                case 3:
                    result.push(<div key={key}>Condenser Model: Kelvion</div>);
                    key++;
                    break;
                case 4:
                    result.push(<div key={key}>Condenser Model: Gunter Large</div>);
                    key++;
                    break;
                case 5:
                    result.push(<div key={key}>Condenser Model: Gunter Small</div>);
                    key++;
                    break;
                case 6:
                    result.push(<div key={key}>Condenser Model: Reevacold</div>);
                    key++;
                    break;

                default:
                    break;
            }
            // 26 Global_Heater_Floor
            if (Number(maskString[26])) {
                result.push(<div key={key}>Floor Heater Enabled</div>);
                key++;
            }
            // 27 Global_Performance_Package
            if (Number(maskString[27])) {
                result.push(<div key={key}>Performance Package Enabled</div>);
                key++;
            }
            // 28 Global_Appearance_Package
            if (Number(maskString[28])) {
                result.push(<div key={key}>Appearance Package Enabled</div>);
                key++;
            }
            // 29 Global_Technology_Package
            if (Number(maskString[29])) {
                result.push(<div key={key}>Technology Package Enabled</div>);
                key++;
            }
            // 30 Global_Solenoid_HGB_S1 (added PLC v10.2)
            if (Number(maskString[30])) {
                result.push(<div key={key}>HGB Solenoid Present</div>);
                key++;
            }
            return result;

        default:
            return result;
    }
}
