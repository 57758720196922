export function unitTypeColorLookup(unitType) {
    switch (`${unitType}`) {
        case "AP":
            return "#33591e";
        case "C1":
            return "#4a148c";
        case "C4":
            return "#5d4037";
        case "E1":
            return "#0d47a1";
        case "EV":
            return "#000000";
        case "PK":
            return "#001549";
        case "RG":
            return "#701c3c";
        default:
            return "#401540";
    }
}
