export function sessionLookup(session) {
    switch (`${session}`) {
        case "0":
            return "NOT READY";
        case "1":
            return "HOLD";
        case "2":
            return "READY";
        case "4":
            return "SESSION ARMED";
        case "8":
            return "SESSION DELAY";
        case "16":
            return "SESSION ACTIVE";
        case "32":
            return "SESSION DONE";
        case "64":
            return "SAFETY VISIBLE";
        case "128":
            return "SAFETY ALARM";
        default:
            return "NULL";
    }
}
