import { useEffect, useState } from "react";

// components
import { MaskParsed } from "./MaskParsed";

// hooks
// ...

// styles
import "../STYLES/Popup.css";

export function PopupBack({ unit }) {
    // let [controlMask, setControlMask] = useState(0);
    // let [appMask, setAppMask] = useState(0);
    // let [serviceMask, setServiceMask] = useState(0);
    let [outputMask, setOutputMask] = useState(0);
    let [warningMask, setWarningMask] = useState(0);
    let [globalMask, setGlobalMask] = useState(0);

    useEffect(() => {
        if (
            unit["masks"].OUT !== undefined &&
            unit["masks"].OUT !== "" &&
            unit["masks"].OUT !== "0" &&
            unit["masks"].OUT !== 0
        )
            setOutputMask(Number(unit["masks"].OUT).toString(2).split("").reverse().join(""));
        else setOutputMask(0);

        if (
            unit["masks"].WRN !== undefined &&
            unit["masks"].WRN !== "" &&
            unit["masks"].WRN !== "0" &&
            unit["masks"].WRN !== 0
        )
            setWarningMask(Number(unit["masks"].WRN).toString(2).split("").reverse().join(""));
        else setWarningMask(0);

        if (
            unit["masks"].GBL !== undefined &&
            unit["masks"].GBL !== "" &&
            unit["masks"].GBL !== "0" &&
            unit["masks"].GBL !== 0
        )
            setGlobalMask(Number(unit["masks"].GBL).toString(2).split("").reverse().join(""));
        else setGlobalMask(0);
    }, [unit]);

    return (
        <div className='mask-categories'>
            <div className='data-extras'>
                {outputMask ? (
                    <MaskParsed maskType={"outputMask"} maskString={outputMask} />
                ) : (
                    "NO 'OUTPUT' MASK"
                )}
            </div>
            <div className='data-extras'>
                {warningMask ? (
                    <MaskParsed maskType={"warningMask"} maskString={warningMask} />
                ) : (
                    "NO 'WARNING' MASK"
                )}
            </div>
            <div className='data-extras'>
                {globalMask ? (
                    <MaskParsed maskType={"globalMask"} maskString={globalMask} />
                ) : (
                    "NO 'GLOBAL' MASK"
                )}
            </div>
        </div>
    );
}
