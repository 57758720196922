import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { createCustomRouter } from "./ROUTES/router";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
//import { Grid } from "./PAGES/STATUSES/COMPONENTS/Grid";

// Create routes
const router = createCustomRouter();

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} style />
        </QueryClientProvider>
    </React.StrictMode>
);
