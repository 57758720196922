import { dataKeyLookup } from "../MISC/dataKeyLookup";
import { sessionLookup } from "../MISC/sessionLookup";
import { statusLookup } from "../MISC/statusLookup";
import "../STYLES/Popup.css";

export function PopupFront({ unit, category }) {
    let valueSwitch = (category, key) => {
        // SWITCH BETWEEN DATA CATEGORIES
        switch (category) {
            case "temperatures":
                return `${Number(unit[category][key]).toFixed(2)} °F`;
            case "vfds":
                if (key.charAt(0) === "K") return `${Number(unit[category][key]).toFixed(2)} KW`;
                else if (key.charAt(0) === "C")
                    return `${Number(unit[category][key]).toFixed(2)} A`;
                else if (key.charAt(0) === "S")
                    return `${Number(unit[category][key]).toFixed(2)} Hz`;
                else return undefined;
            case "pressures":
                return `${Number(unit[category][key]).toFixed(2)} psi`;
            case "statuses":
                if (key === "SYS") return `${statusLookup(unit[category][key])}`;
                else if (key === "SES") return `${sessionLookup(unit[category][key])}`;
                else if (key === "AS") return String(unit[category][key]).replace(",", "");
                else if (key === "SSC" || key === "SG1")
                    if (Boolean(Number(unit[category][key]))) return " Open";
                    else return " Closed";
                else if (key === "DR" || key === "SS2" || key === "SR2" || key === "SL1")
                    if (Boolean(Number(unit[category][key]))) return " Closed";
                    else return " Open";
                else return undefined;

            case "system":
                if (key !== "CPU" && key !== "MEM" && key !== "PRO") {
                    if (key === "AMB") return `${Number(unit[category][key]).toFixed(2)} °F`;
                    if (key === "HUM") return `${Number(unit[category][key]).toFixed(2)} %`;
                    else if (key === "PV") return `${Number(unit[category][key]).toFixed(1)}`;
                    else if (key === "CV") return `${unit[category][key]}`;
                    else return `${unit[category][key]}`;
                } else return undefined;

            case "exvs":
                return `${Number(unit[category][key]).toFixed(2)} %`;

            default:
                return undefined;
        }
    };
    let alternateCellStyle = false;

    let labelColumn = (
        <div className='cell-labels-column'>
            {Object.keys(unit[category]).map((key) => {
                if (
                    unit[category][key] !== null &&
                    unit[category][key] !== "" &&
                    key !== "CPU" &&
                    key !== "MEM" &&
                    key !== "PRO"
                ) {
                    let labelCell = (
                        <div
                            key={key}
                            className={alternateCellStyle ? "cellHighlight1" : "cellHighlight2"}
                        >
                            <div className='cellWrap'>{dataKeyLookup(key)}</div>
                        </div>
                    );

                    alternateCellStyle = !alternateCellStyle;
                    return labelCell;
                } else return undefined;
            })}
        </div>
    );

    alternateCellStyle = false;

    let valueColumn = (
        <div className='cell-values-column'>
            {Object.keys(unit[category]).map((key) => {
                let innerValue = valueSwitch(category, key);

                let valueCell = unit[category][key] !== null && unit[category][key] !== "" && (
                    <div
                        key={key}
                        className={alternateCellStyle ? "cellHighlight1" : "cellHighlight2"}
                    >
                        <div className='cellWrap'>{innerValue}</div>
                    </div>
                );
                if (
                    innerValue !== undefined &&
                    unit[category] !== undefined &&
                    unit[category] !== "" &&
                    unit[category][key] !== undefined &&
                    unit[category][key] !== "" &&
                    unit[category][key] !== null
                )
                    alternateCellStyle = !alternateCellStyle;
                return valueCell;
            })}
        </div>
    );

    return (
        <div className='category-box'>
            <div className='catagory-box-title'>{`${category}`.toUpperCase()}</div>
            <div className='cell-labels-and-values-grid'>
                {labelColumn}
                {valueColumn}
            </div>
        </div>
    );
}
