import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function useRecordsQuery() {
    const recordsQuery = useQuery({
        queryKey: ["unit_records"],
        queryFn: async () => {
            const response = await axios.get("https://app.cryonexus.com/api/records");
            return response.data;
        },
        refetchInterval: 5000,
    });
    // const faultsQuery = useQuery({
    //     queryKey: ["unit_faults"],
    //     queryFn: async () => {
    //         const response = await axios.get("https://app.cryonexus.com/api/faults");
    //         return response.data;
    //     },
    //     refetchInterval: 5000,
    // });
    return { recordsQuery: recordsQuery };
}
