import { useState } from "react";

// components
import { PopupFront } from "./PopupFront";
import { PopupBack } from "./PopupBack";

// hooks
import { unitTypeColorLookup } from "../MISC/unitTypeColorLookup";

// styles
import "../STYLES/Popup.css";

export default function PopupInfo({ unit, showPopup, setShowPopup }) {
    //save for laters
    //<a href={"https://app.cryonexus.com/graph/" + props.info.id + "/" + props.info.name}>{props.info.name.slice(3,)}</a>

    const [showPopupFront, setShowPopupFront] = useState(true);

    function closePopup(e) {
        if (showPopup) setShowPopup(false);
        document.body.style.overflow = "scroll";
        e.stopPropagation();
    }

    function flipPopup(e) {
        setShowPopupFront(!showPopupFront);
    }

    return (
        <div className='popup-overlay' onClick={closePopup}>
            <div
                className='popup-background'
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div
                    className='popup-title'
                    style={{
                        backgroundColor: unitTypeColorLookup(unit.name.slice(0, 2)),
                    }}
                >
                    <div>
                        ({unit.id}) {unit.name.slice(3)}
                    </div>
                    <div className='graph-subtag'>- Click for Graph -</div>
                </div>

                <div className='popup' onClick={flipPopup}>
                    {showPopupFront ? (
                        <div className='data-categories'>
                            <PopupFront unit={unit} category={"temperatures"} />
                            <PopupFront unit={unit} category={"vfds"} />
                            <PopupFront unit={unit} category={"pressures"} />
                            <PopupFront unit={unit} category={"statuses"} />
                            <PopupFront unit={unit} category={"system"} />
                            <PopupFront unit={unit} category={"exvs"} />
                        </div>
                    ) : (
                        <PopupBack unit={unit} />
                    )}
                </div>

                <div
                    className='popup-close-btn'
                    onClick={(e) => {
                        //console.log(e.currentTarget.className);
                        if (showPopup) setShowPopup(false);
                        document.body.style.overflow = "scroll";
                    }}
                >
                    X
                </div>
            </div>
        </div>
    );
}
