export function dataKeyLookup(key) {
    switch (key) {
        case "X1":
            return "EXV 1";
        case "X2":
            return "EXV 2";
        case "X3":
            return "EXV 3";
        case "X4":
            return "EXV 4";

        case "PD1":
            return "Discharge 1";
        case "PD2":
            return "Discharge 2";
        case "PD3":
            return "Discharge 3";
        case "PS1":
            return "Suction 1";
        case "PS2":
            return "Suction 2";
        case "PS3":
            return "Suction 3";
        case "PO1":
            return "Oil Sep 1";
        case "PO2":
            return "Oil Sep 2";
        case "PO3":
            return "Oil Sep 3";

        case "TD1":
            return "Discharge 1";
        case "TD2":
            return "Discharge 2";
        case "TD3":
            return "Discharge 3";
        case "TS1":
            return "Suction 1";
        case "TS2":
            return "Suction 2";
        case "TS3":
            return "Suction 3";
        case "TSP":
            return "Suction PC";
        case "TI1":
            return "Inlet 1";
        case "TI2":
            return "Inlet 2";
        case "TI3":
            return "Inlet 3";
        case "TPC":
            return "Prechamber";
        case "TMC":
            return "Main Chamber";
        case "TWC":
            return "Windchill";
        case "TSC":
            return "Subcooler";
        case "TMR":
            return "Machine Room";
        case "TWI":
            return "Water Inlet";
        case "TWO":
            return "Water Outlet";
        case "TSH":
            return "404A Superheat";

        case "SYS":
            return "System";
        case "SES":
            return "Session";
        case "AS":
            return "Alert";
        case "DR":
            return "Door";
        case "SSC":
            return "SOL. SubCooler";
        case "SL1":
            return "SOL. Liquid Line";
        case "SG1":
            return "SOL. Hot Gas Bypass";
        case "SS2":
            return "SOL. S2 EXP Supply";
        case "SR2":
            return "SOL. S2 EXP Return";

        case "CPU":
            return "CPU";
        case "MEM":
            return "Memory";
        case "AMB":
            return "Ambient";
        case "HUM":
            return "Humidity";
        case "PRO":
            return "Processes";
        case "GPM":
            return "GPM";
        case "PV":
            return "Panel Version";
        case "CV":
            return "Code Version";

        case "KWU":
            return "KW Usage";
        case "CC1":
            return "Comp 1 Current";
        case "CC2":
            return "Comp 2 Current";
        case "CC3":
            return "Comp 3 Current";
        case "CPC":
            return "PC Evap Current";
        case "CEV":
            return "MC Evap Current";
        case "CCD":
            return "Condenser Current";
        case "SC1":
            return "Comp 1 Speed";
        case "SC2":
            return "Comp 2 Speed";
        case "SC3":
            return "Comp 3 Speed";
        case "SPC":
            return "PC Evap Speed";
        case "SEV":
            return "MC Evap Speed";
        case "SCD":
            return "Cond Speed";

        default:
            return "UNKNOWN";
    }
}
