export function sessionColorLookup(session) {
    switch (`${session}`) {
        case "1":
            return "#00e5ff";
        case "2":
            return "#0288d1";
        case "4":
            return "#00e5ff";
        case "8":
            return "#00e5ff";
        case "16":
            return "#64dd17";
        case "32":
            return "#64dd17";
        case "64":
            return "#7c4dff";
        case "128":
            return "#ffff00";
        default:
            return "#AF8080";
    }
}
