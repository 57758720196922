export function statusLookup(status) {
    switch (`${status}`) {
        case "0":
            return "OFFLINE";
        case "2":
            return "BOOTING";
        case "4":
            return "LOADING";
        case "8":
            return "STANDBY";
        case "16":
            return "COOLING";
        case "32":
            return "DEFROST";
        case "64":
            return "INSPECT";
        case "128":
            return "SERVICE";
        case "256":
            return "SAFETY DEFROST";
        default:
            return "NULL";
    }
}
