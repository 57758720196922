import { createHashRouter } from "react-router-dom";

// pages
import ErrorPage from "../PAGES/ErrorPage.js";
import { Grid } from "../PAGES/STATUSES/COMPONENTS/Grid.js";

export function createCustomRouter() {
    return createHashRouter([
        {
            path: "/",
            element: <Grid />, //replace with root route? send root rout to grid?
            errorElement: <ErrorPage />,
        },
        // React router supposedly is not sensitive to case by default(?)

    ]);
}
