import { createPortal } from "react-dom";
import { useRef, useState } from "react";

//components
import Popup from "./Popup.js";
import Tile from "./Tile.js";

//hooks
import useRecordsQuery from "../HOOKS/useRecordsQuery.js";

//styles
import "../STYLES/Grid.css";

export function Grid() {
    // Nav Bar? Search for units. Mode to show only C1s, EVs, APs, etc.
    // Banner for inspects/services? Directly relocate systems to this banner and color them red?
    // Grid Container?
    // Get records state to use anywhere
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);

    const { recordsQuery } = useRecordsQuery();

    if (!recordsQuery.isLoading && !recordsQuery.isError) {
        var records = recordsQuery.data;

        var recordsByModel = {
            C1: [],
            C4: [],
            E1: [],
            AP: [],
            BC: [],
            PK: [],
            RG: [],
        };

        // Arrange the units into their unit type/model.
        for (let unitID in records) {
            // ID is not undefined, model is not UNKNOWN
            if (records[unitID]) {
                try {
                    // Push entire record into appropriate model list
                    recordsByModel[unitID.slice(0, 2)].push(records[unitID]);
                } catch (e) {
                    console.log(records[unitID], ":", e);
                }
            } else {
                console.log(records[unitID], "Invalid record format.");
            }
        }

        // Alphabetize each model list.
        for (let modelList in recordsByModel) {
            recordsByModel[modelList].sort((a, b) => {
                // Standard SORT function format.
                if (a.name && b.name) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    } else if (b.name.toLowerCase() < a.name.toLowerCase()) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else return 0;
            });
        }

        var tileList = [];

        Object.keys(recordsByModel).forEach((model) => {
            //tileList.push(<div className="modelHeader">{model}</div>)
            for (const record of recordsByModel[model]) {
                tileList.push(
                    <Tile
                        key={record.id}
                        unit={record}
                        setShowPopup={setShowPopup}
                        popupRef={popupRef}
                    />
                );
            }
        });
    }
    return (
        <div>
            {showPopup &&
                createPortal(
                    <Popup
                        unit={records[popupRef.current]}
                        showPopup={showPopup}
                        setShowPopup={setShowPopup}
                    />,
                    document.body
                )}
            {
                //NAV BAR (hidden for "display mode" of status screen)
                //FAULT HISTORY (toggleable)
                //SHOW DIFFERENT UNITS PER URL AND/OR TOGGLED
            }

            <div className='grid-container'>
                {
                    tileList // All of the unit tiles
                }
                {
                    tileList ? tileList.length : <></> // Show tile count (number of units) at bottom of screen.
                }
            </div>
        </div>
    );
}
