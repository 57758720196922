import { useEffect, useState } from "react";
import { unitTypeColorLookup } from "../MISC/unitTypeColorLookup";
import { statusColorLookup } from "../MISC/statusColorLookup";
import { sessionColorLookup } from "../MISC/sessionColorLookup";
import { statusLookup } from "../MISC/statusLookup";
import { sessionLookup } from "../MISC/sessionLookup";

export default function useTileSettings(unit) {
    let [status, setStatus] = useState("NULL");
    let [statusColor, setStatusColor] = useState("#18ffff");
    let [backgroundColor, setBackgroundColor] = useState("#424242");
    let [unitColor, setUnitColor] = useState("rgba(0, 0, 40, 0.9)");

    // eslint-disable-next-line eqeqeq
    let [faulted, setFaulted] = useState(unit.statuses.SYS !== undefined ? unit.statuses.SYS == 64 || unit.statuses.SYS == 128 : false);

    let offline;
    if (unit.time) {
        offline = Date.now() - unit.time > 30000;
    }

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        setFaulted(unit.statuses.SYS == 64 || unit.statuses.SYS == 128);
    }, [faulted, unit.statuses.SYS]);

    useEffect(
        () => {
            // Set the status
            if (offline) {
                setStatus("OFFLINE");
                //${new Date(unit.time).getFullYear()}/${new Date(unit.time).getMonth() + 1}/${new Date(unit.time).getDate()}`;
            } else if (unit.statuses.SES !== "") {
                if (unit.statuses.SES > 0 && !faulted) setStatus(sessionLookup(unit.statuses.SES));
                else setStatus(statusLookup(unit.statuses.SYS));
            }

            // Set the color of the status text
            setStatusColor(
                offline
                    ? statusColorLookup(0)
                    : unit.statuses.SES > 0 && !faulted
                    ? sessionColorLookup(unit.statuses.SES)
                    : statusColorLookup(unit.statuses.SYS)
            );

            // Set the color of the background of the tile based on status.
            // TODO: Make this more dynamic for options other than being faulted.
            setBackgroundColor(!offline && faulted ? "#b00030" : "#252535");

            // Set unit name color
            setUnitColor(unitTypeColorLookup(unit.name.slice(0, 2)));
        },
        /* useEffect Dependencies */
        [unit.name, unit.statuses.SES, unit.statuses.SYS, offline, unit.time, unit.id, faulted]
    );

    return {
        offline: offline,
        status: status,
        statusColor: statusColor,
        backgroundColor: backgroundColor,
        unitColor: unitColor,
    };
}
