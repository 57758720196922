export function statusColorLookup(status) {
    switch (`${status}`) {
        case "0":
            return "#AF8080";
        case "2":
            return "#eeeeee";
        case "4":
            return "#bdbdbd";
        case "8":
            return "#70804b";
        case "16":
            return "#18ffff";
        case "32":
            return "#7e57c2";
        case "64":
            return "#000000";
        case "128":
            return "#000000";
        case "256":
            return "#fdd835";
        default:
            return "#000000";
    }
}
