import "../STYLES/Tile.css";
import useTileSettings from "../HOOKS/useTileSettings.js";

export default function Tile({ unit, setShowPopup, popupRef }) {
    let { offline, status, statusColor, backgroundColor, unitColor } = useTileSettings(unit);

    return (
        <div
            className='tile'
            style={{ backgroundColor: backgroundColor }}
            onClick={(e) => {
                setShowPopup(true);
                popupRef.current = unit.id;
                document.body.style.overflow = "hidden";
            }}
        >
            <span className='location-name' style={{ backgroundColor: unitColor }}>
                {unit.name.slice(3)}
            </span>

            <div
                className='status'
                style={{ color: statusColor, backgroundColor: backgroundColor }}
            >
                {status}
                {!offline &&
                unit.statuses.SYS !== undefined &&
                (unit.statuses.SYS === 64 || unit.statuses.SYS === 128)
                    ? " " + unit.statuses.AS
                    : undefined}
            </div>
        </div>
    );
}
